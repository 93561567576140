import React from 'react';
import PortfolioElement from '../../atoms/PortfolioElement/PortfolioElement';
import './Portfolio.scss';
import projects from '../../../projects.json';
import { PortfolioProject } from '../../../types/portfolio';

const Portfolio = (): JSX.Element => {
  return (
    <div className='parent'>
      {(projects as PortfolioProject[]).map((project, i) => (
        <PortfolioElement key={project.title} project={project} index={i} />
      ))}
    </div>
  );
};

export default Portfolio;
