import React, { useMemo } from 'react';
import './Navbar.scss';
import { AiOutlineHome, AiOutlineUser, AiOutlineProject } from 'react-icons/ai';
import { RiArticleLine } from 'react-icons/ri';
import HoverButton from '../../atoms/HoverButton/HoverButton';
import { Link, useLocation } from 'react-router-dom';

const defaultColor = '#3a3a3a';
const highlightedColor = '#3ac070';

const Navbar = (): JSX.Element => {
  const { pathname: url } = useLocation();

  const elementColors = useMemo(
    () => [
      url === '/' ? highlightedColor : defaultColor, // home
      url === '/about' ? highlightedColor : defaultColor, // about
      url === '/portfolio' ? highlightedColor : defaultColor, // portfolio
      url === '/cv' ? highlightedColor : defaultColor, // cv
    ],
    [url]
  );

  return (
    <nav className='navbar'>
      {/* home */}
      <HoverButton
        defaultText={
          <Link to='/'>
            <AiOutlineHome color={elementColors[0]} size={'3rem'} />
          </Link>
        }
        hoverText={
          <Link style={{ color: elementColors[0] }} to='/'>
            Home
          </Link>
        }
        fontSize='1.8rem'
      />
      {/* About */}
      <HoverButton
        defaultText={
          <Link to='/about'>
            <AiOutlineUser color={elementColors[1]} size={'3rem'} />
          </Link>
        }
        hoverText={
          <Link to='/about'>
            <p style={{ color: elementColors[1] }}>About</p>
          </Link>
        }
        fontSize='1.8rem'
      />
      {/* Portfolio */}
      <HoverButton
        defaultText={
          <Link to='portfolio'>
            <AiOutlineProject color={elementColors[2]} size={'3rem'} />
          </Link>
        }
        hoverText={
          <Link style={{ color: elementColors[2] }} to='/portfolio'>
            Portfolio
          </Link>
        }
        fontSize='1.8rem'
      />
      {/* CV */}
      <HoverButton
        defaultText={
          <Link to='cv'>
            <RiArticleLine color={elementColors[3]} size={'3rem'} />
          </Link>
        }
        hoverText={
          <Link style={{ color: elementColors[3] }} to='cv'>
            CV
          </Link>
        }
        fontSize='1.8rem'
      />
    </nav>
  );
};

export default Navbar;
