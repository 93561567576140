import React from 'react';
import './Frontpage.scss';
import Logo from '../../atoms/Logo/Logo';
import useDelay from '../../../hooks/useDelay';
import Carousel from '../../atoms/Carousel/Carousel';

const Frontpage = (): JSX.Element => {
  const showHelloText = useDelay(1000);
  const showNameText = useDelay(1500);

  return (
    <div className='frontpage-wrapper'>
      <Logo />
      <div className='text-wrapper'>
        <h1 className={`helloWorldText ${showHelloText ? '' : 'hide'}`}>
          Hello World
        </h1>
        <h2 className={`myNameText ${showNameText ? '' : 'hide'}`}>
          I&apos;m Andreas
        </h2>
      </div>
      <Carousel
        strings={[
          '📱 mobile apps',
          '🕹 embedded systems',
          '💻 websites',
          '🎮 video games',
        ]}
        delay={4000}
      />
    </div>
  );
};

export default Frontpage;
