import React, { useState } from "react";
import useDelay from "../../../hooks/useDelay";
import "./Carousel.scss";

interface Props {
  strings: string[];
  delay: number;
}

const Carousel = ({ strings, delay }: Props): JSX.Element => {
  const show = useDelay(delay);
  const [index, setIndex] = useState(0);

  setTimeout(() => {
    if (index === strings.length - 1) setIndex(0);
    else setIndex(index + 1);
  }, 2000);

  return (
    <div className={`carousel-wrapper`}>
      <p className={`pretext ${show ? "" : "hide"}`}>I make</p>
      <br className="linebreak" />
      <div className="carousel">
        <ul
          className="slider"
          style={{
            top: `-${index * 100}px`,
          }}
        >
          {strings.map((s, i) => (
            <li
              key={s}
              className={show ? "" : "hide"}
              style={{
                top: `${i * 100}px`,
              }}
            >
              {s}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Carousel;
