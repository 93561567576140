import * as THREE from "three";

/**
 * Returns the width of the mesh with the highest width
 * @param meshes - an array of meshes
 */
export default (meshes: Array<THREE.Mesh>): number =>
  meshes.reduce<number>((maxWidth, mesh) => {
    const bbox = new THREE.Box3().setFromObject(mesh);
    const max = bbox.max.x;
    const min = bbox.min.x;
    if (!isFinite(max) || !isFinite(min)) return maxWidth;
    const width = Math.abs(max - min);
    return Math.max(maxWidth, width);
  }, 0);
