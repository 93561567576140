import { useState } from "react";

const useDelay = (time: number): boolean => {
  const [isFinished, setIsFinished] = useState(false);

  setTimeout(() => {
    setIsFinished(true);
  }, time);

  return isFinished;
};

export default useDelay;
