import React from 'react';
import PdfNavButton from '../../atoms/PdfNavButton/PdfNavButton';

type Props = {
  height: number;
  show: boolean;
  showLeft: boolean;
  showRight: boolean;
  onClick: (increment: boolean) => void;
};

const PdfNavButtons = ({
  height,
  show,
  showLeft,
  showRight,
  onClick,
}: Props): JSX.Element => {
  if (!show) return <></>;
  return (
    <div
      style={{
        transform: `translateY(-${height}px)`,
        height: `${height}px`,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <PdfNavButton
        show={showLeft}
        direction='Left'
        onClick={() => onClick(false)}
      />
      <PdfNavButton
        show={showRight}
        direction='Right'
        onClick={() => onClick(true)}
      />
    </div>
  );
};

export default PdfNavButtons;
