import React from 'react';
import './PdfNavButton.scss';

type Props = {
  direction: NavDirection;
  show: boolean;
  onClick: () => void;
};

export type NavDirection = 'Left' | 'Right';

const PdfNavButton = ({ direction, show, onClick }: Props) => {
  if (!show) return <div className='hidden' />;

  const image = direction === 'Left' ? 'images/leftButton.png' : 'images/rightButton.png';

  return (
    <div className='pdf-nav-button-wrapper'>
      <div className='pdf-nav-button' onClick={onClick}>
        <img src={image} />
      </div>
    </div>
  );
};

export default PdfNavButton;
