import { Technology } from '../types/portfolio';

export default (technology: Technology): string => {
  switch (technology) {
    case 'C#':
      return '/images/technologies/csharp_64x64.png';
    case 'C':
      return '/images/technologies/c_64x64.png';
    case 'D3':
      return '/images/technologies/d3.png';
    case 'Haskell':
      return '/images/technologies/haskell_64x64.png';
    case 'JavaScript':
      return '/images/technologies/javascript_64x64.png';
    case 'TypeScript':
      return '/images/technologies/typescript_64x64.png';
    case 'Kotlin':
      return '/images/technologies/kotlin_64x64.png';
    case 'LibGDX':
      return '/images/technologies/LibGDX-white.png';
    case 'React':
      return '/images/technologies/react.png';
    case 'Unity':
      return '/images/technologies/unity-white.png';
    default:
      console.log('Could not find technology ' + technology);
      return '';
  }
};
