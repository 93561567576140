import * as THREE from 'three';
import { clamp } from 'three/src/math/MathUtils';
import fooFont from '../fonts/Raleway_ExtraBold.json';
import { Stage } from '../types/spaceSimulation';
import getHighestWidth from './utils/getHighestWidth';

const CONSTANTS = {
  particleCount: 6000,
  camera: {
    fov: 75,
    aspectRatio: window.innerWidth / window.innerHeight,
    nearClipPlane: 0.2,
    farClipPlane: 100,
  },
  textGeometry: {
    size: 1,
    height: 0.01,
    curveSegments: 4,
    bevelEnabled: false,
  },
  minVelocities: {
    about: 25,
    transition: 80,
    skills: 200,
  },
  // minVelocity: 30,
  aboutStrings: [
    'A long time ago in a galaxy far, far away...',
    'there was an IT student named Andreas, with',
    'a passion for making stuff that looks cool!',
    ' ',
    'I am an enthusiastic and hard-working guy who',
    'loves to play and experiment with technology.',
    'I like to make games, websites and anything',
    'that lets me challenge myself and try new things.',
    ' ',
    "I am about to start my final year of a master's",
    'degree in informatics at NTNU in Trondheim, Norway,',
    "and I'm currently doing a summer internship at ",
    'Accenture in Oslo. During this summer I will be',
    'looking for a full-time job starting next fall.',
    ' ',
    'If you are an employer and interested in hiring me',
    'please keep scrolling and have a look at my portfolio.',
    ' ',
    "If you're here just to be inspired by some of the",
    "cool stuff I've made, feel free to have a look as well!",
  ],
  skills: [
    'Java',
    '.NET',
    'React',
    'Git',
    'TypeScript',
    'C#',
    'D3.js',
    'Unity',
    'Flask',
    'GraphDB',
    'HTML',
    'Kotlin',
    'C++',
    'JavaScript',
    'CSS',
    'SPARQL',
    'MySQL',
    'Three.js',
    'Python',
    'MongoDB',
    'GraphQL',
  ],
};

export default class {
  private foo = false;
  private canvas: HTMLCanvasElement;
  private scene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;

  private starGeometry: THREE.BufferGeometry;
  private starMat: THREE.PointsMaterial;
  private starMesh: THREE.Points;
  private starsCoords: Float32Array;

  private aboutTextMeshes: Array<
    THREE.Mesh<THREE.TextGeometry, THREE.MeshPhongMaterial>
  >;
  private get lastAboutTextMesh(): THREE.Mesh {
    return this.aboutTextMeshes[this.aboutTextMeshes.length - 1];
  }
  private skillsTextMeshes?: Array<
    THREE.Mesh<THREE.TextGeometry, THREE.MeshPhongMaterial>
  >;
  private skillsIntroTextMesh?: THREE.Mesh<
    THREE.TextGeometry,
    THREE.MeshPhongMaterial
  >;
  private transitionToPortfolioTextMesh?: THREE.Mesh<
    THREE.TextGeometry,
    THREE.MeshPhongMaterial
  >;

  private camera: THREE.PerspectiveCamera;

  private clock: THREE.Clock;
  private timeStamp = 0;
  private velocity = 0;
  private stage: Stage;

  private onFinish: () => void;

  private disabledScroll = false;

  constructor(canvas: HTMLCanvasElement, stage: Stage, onFinish: () => void) {
    this.canvas = canvas;
    this.stage = stage;
    this.onFinish = onFinish;

    this.scene = new THREE.Scene();

    this.initLights();

    this.camera = this.initCamera();

    // create stars
    this.starGeometry = new THREE.BufferGeometry();
    this.starMat = new THREE.PointsMaterial({
      size: 0.005,
    });
    this.starsCoords = this.initStars();
    this.starGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(this.starsCoords, 3)
    );
    this.starMesh = new THREE.Points(this.starGeometry, this.starMat);
    this.scene.add(this.starMesh);

    // create about text
    this.aboutTextMeshes = this.initAboutText();
    this.aboutTextMeshes.forEach((mesh) => this.scene.add(mesh));

    this.renderer = this.initRenderer();

    this.clock = new THREE.Clock();
    this.tick();

    window.addEventListener('wheel', this.onScroll);

    console.log(`Width: ${window.innerWidth}, height: ${window.innerHeight}`);
    if (window.innerWidth < window.innerHeight) {
      console.log('flip your screen!');
      this.scene.background = new THREE.Color(0xff0000);
    }
  }

  private initStars = (): Float32Array => {
    const stars = new Float32Array(CONSTANTS.particleCount * 3);

    for (let i = 0; i < CONSTANTS.particleCount * 3; i++) {
      if (i % 3 === 2) stars[i] = -Math.random() * 2500;
      else stars[i] = 0.5 + (Math.random() - 0.5) * 100;
    }

    return stars;
  };

  private movePassedStars = () => {
    if (this.foo) return;
    this.foo = true;
    // console.log("move passed stars");
    for (let i = 2; i < this.starsCoords.length; i += 3) {
      if (this.starsCoords[i] > this.camera.position.z)
        this.starsCoords[i] -= 100;
    }

    this.starGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(this.starsCoords, 3)
    );

    this.starMesh = new THREE.Points(this.starGeometry, this.starMat);
  };

  private initLights = (): void => {
    this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));
    const pointLight = new THREE.PointLight(0xffffff, 0.1);
    pointLight.position.x = 0;
    pointLight.position.y = 0;
    pointLight.position.z = 4;
  };

  private initCamera = (): THREE.PerspectiveCamera => {
    const camera = new THREE.PerspectiveCamera(
      ...Object.values(CONSTANTS.camera)
    );

    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 2;

    return camera;
  };

  private initRenderer = (): THREE.WebGLRenderer => {
    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.render(this.scene, this.camera);

    return renderer;
  };

  private initAboutText = (): Array<
    THREE.Mesh<THREE.TextGeometry, THREE.MeshPhongMaterial>
  > => {
    const loader = new THREE.FontLoader();
    const font = loader.parse(fooFont);

    const geometryParams = {
      ...CONSTANTS.textGeometry,
      font: font,
    };
    const geometries = CONSTANTS.aboutStrings.map(
      (str) => new THREE.TextGeometry(str, geometryParams)
    );

    const textMat = new THREE.MeshPhongMaterial({
      color: 0xfafa00,
      specular: 0xfafa00,
      shininess: 30,
    });

    const meshes = geometries.map((g) => new THREE.Mesh(g, textMat));

    const totalWidth = getHighestWidth(meshes);

    meshes.forEach((mesh, i) => {
      mesh.rotation.x = -Math.PI / 3;
      const bbox = new THREE.Box3().setFromObject(mesh);
      mesh.position.x =
        (totalWidth - Math.abs(bbox.max.x - bbox.min.x)) / 2 - totalWidth / 2;
      mesh.position.y = -15;
      mesh.position.z = -10;
      mesh.translateY(-i * 3);
    });

    return meshes;
  };

  private removeAboutText = () => {
    this.aboutTextMeshes.forEach((mesh) => {
      mesh.material.transparent = true;
      mesh.material.opacity = 0;
    });
  };

  private initSkillsText = (): Array<
    THREE.Mesh<THREE.TextGeometry, THREE.MeshPhongMaterial>
  > => {
    const loader = new THREE.FontLoader();
    const font = loader.parse(fooFont);

    const geometryParams = {
      ...CONSTANTS.textGeometry,
      font: font,
    };
    const geometries = CONSTANTS.skills.map(
      (str) => new THREE.TextGeometry(str, geometryParams)
    );
    geometries.forEach((g) => g.center());

    const textMat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      specular: 0xffffff,
      shininess: 30,
    });

    const meshes = geometries.map((g) => new THREE.Mesh(g, textMat));

    const positions = [
      { x: 7, y: 3 },
      { x: -5, y: -7 },
      { x: -7, y: 1 },
      { x: 6, y: -6 },
      { x: 6, y: 3 },
      { x: -2, y: -5 },
      { x: -5, y: 5 },
      { x: 2, y: -6 },
    ];
    const camYPos = this.camera.position.y;

    meshes.forEach((mesh, i) => {
      const z = -200 - i * 70;

      mesh.position.z = z;
      mesh.position.x = positions[i % positions.length].x;
      mesh.position.y = positions[i % positions.length].y + camYPos;

      const lookPos = new THREE.Vector3(0, camYPos, z);
      mesh.lookAt(lookPos);

      this.scene.add(mesh);
    });

    return meshes;
  };

  private initSkillsIntroText = (): THREE.Mesh<
    THREE.TextGeometry,
    THREE.MeshPhongMaterial
  > => {
    const loader = new THREE.FontLoader();
    const font = loader.parse(fooFont);

    const geometryParams = {
      ...CONSTANTS.textGeometry,
      font: font,
      height: 0.001,
      size: 0.2,
    };

    const geometry = new THREE.TextGeometry(
      'Here are some of the languages and frameworks I am proficient in',
      geometryParams
    );
    geometry.center();

    const mat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      specular: 0xffffff,
      shininess: 30,
      transparent: true,
      opacity: 0,
    });

    const mesh = new THREE.Mesh(geometry, mat);

    mesh.position.y = this.camera.position.y;
    mesh.position.z = -5;

    this.scene.add(mesh);

    return mesh;
  };

  private initTransitionToPortfolioText = (): THREE.Mesh<
    THREE.TextGeometry,
    THREE.MeshPhongMaterial
  > => {
    const loader = new THREE.FontLoader();
    const font = loader.parse(fooFont);

    const geometryParams = {
      ...CONSTANTS.textGeometry,
      font: font,
      height: 0.001,
      size: 0.2,
    };

    const geometry = new THREE.TextGeometry(
      'And here are some of my projects',
      geometryParams
    );
    geometry.center();

    const mat = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      specular: 0xffffff,
      shininess: 30,
      transparent: true,
      opacity: 1,
    });

    const mesh = new THREE.Mesh(geometry, mat);

    mesh.position.y = this.camera.position.y;
    mesh.position.z = this.camera.position.z - 6;

    this.scene.add(mesh);

    return mesh;
  };

  tick = (): void => {
    const elapsedTime = this.clock.getElapsedTime();
    const deltaTime = elapsedTime - this.timeStamp;
    this.timeStamp = elapsedTime;

    switch (this.stage) {
      case 'NONE':
        break;
      case 'about': {
        const deltaPos = Math.max(
          0.1 * (this.velocity + CONSTANTS.minVelocities['about']) * deltaTime,
          -5
        );
        this.aboutTextMeshes.forEach((mesh) => mesh.translateY(deltaPos));
        this.starMesh.rotation.z += 0.03 * deltaTime;

        this.velocity *= 0.03;

        if (this.lastAboutTextMesh.position.z < -28) {
          this.stage = 'transition';
        }
        break;
      }
      case 'transition': {
        const textDeltaPos =
          0.2 *
          (this.velocity + CONSTANTS.minVelocities['transition']) *
          deltaTime;
        this.aboutTextMeshes.forEach((mesh) => mesh.translateY(textDeltaPos));
        this.starMesh.rotation.z += 0.03 * deltaTime;

        const camDeltaPos =
          -0.04 *
          (this.velocity + CONSTANTS.minVelocities['transition']) *
          deltaTime;
        this.camera.position.y += camDeltaPos;

        this.velocity *= 0.5;

        if (this.camera.position.y < -25) {
          this.stage = 'skills';
          this.velocity = 0;
          this.removeAboutText();
          this.skillsIntroTextMesh = this.initSkillsIntroText();
          this.skillsTextMeshes = this.initSkillsText();
        }
        break;
      }
      case 'skills': {
        let deltaPos =
          -0.5 *
          (this.velocity + CONSTANTS.minVelocities['skills']) *
          deltaTime;
        if (this.camera.position.z > -5) {
          deltaPos *= 0.015;
          if (this.skillsIntroTextMesh) {
            const bar = ((this.camera.position.z + 5) / 7) * Math.PI;
            const opacity = Math.sin(bar);
            this.skillsIntroTextMesh.material.opacity = clamp(opacity, 0, 1);
          }
        }
        this.camera.position.z += deltaPos;

        this.skillsTextMeshes?.forEach((mesh) => {
          const distanceToCamera = this.camera.position.z - mesh.position.z;
          if (distanceToCamera < 15 && distanceToCamera > 0) {
            mesh.position.z += deltaPos * 0.95;
          }
        });

        this.velocity *= 0.95;

        if (this.camera.position.z < -1900) {
          this.velocity += CONSTANTS.minVelocities['skills'];
          this.stage = 'finishing';
          this.transitionToPortfolioTextMesh =
            this.initTransitionToPortfolioText();
          break;
        }
        break;
      }
      case 'finishing': {
        const deltaPos = -0.5 * this.velocity * deltaTime;
        this.camera.position.z += deltaPos;

        if (this.transitionToPortfolioTextMesh) {
          this.transitionToPortfolioTextMesh.position.z += deltaPos;
        }

        this.skillsTextMeshes?.forEach((mesh) => {
          const distanceToCamera = this.camera.position.z - mesh.position.z;
          if (distanceToCamera < 15 && distanceToCamera > 0) {
            mesh.position.z += deltaPos * 0.95;
          }
        });

        this.velocity *= 0.98;

        if (this.velocity < 1) {
          this.stage = 'NONE';
          this.onFinish();
        }

        break;
      }
    }

    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(() => this.tick());
  };

  private onScroll = (ev: WheelEvent) => {
    if (this.disabledScroll) return;
    this.velocity = clamp(this.velocity + ev.deltaY, -200, 200);
  };
}
