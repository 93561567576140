import React from 'react';
import './ProjectDetails.scss';
import { PortfolioProject } from '../../../types/portfolio';
import technologyToIcon from '../../../utils/technologyToIcon';

type Props = {
  project: PortfolioProject;
};

const ProjectDetails = ({ project }: Props): JSX.Element => {
  return (
    <div className='project-details-wrapper'>
      <div className='project-details-images'>
        {project.youtubeUrl && (
          <div>
            <iframe
              width='500'
              height='300'
              src={project.youtubeUrl}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        )}
        {project.otherImages.map((image) => (
          <img key={image} src={image} />
        ))}
      </div>
      <div className='project-details-content-wrapper'>
        <h2 className='project-details-title'>{project.title}</h2>
        <summary className='project-details-abstract'>
          {project.abstract}
        </summary>
        <div className='project-details-technologies'>
          {project.technologies.map((tech) => (
            <img
              className='project-details-technologies-icon'
              key={tech}
              src={technologyToIcon(tech)}
            />
          ))}
        </div>
        <div className='project-details-buttons'></div>
        <article>
          {project.description.map((text) => (
            <p
              key={text.substring(0, 10)}
              className='project-details-description'
            >
              {text}
            </p>
          ))}
        </article>
      </div>
    </div>
  );
};

export default ProjectDetails;
