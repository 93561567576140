import React from 'react';
import './App.css';
import Navbar from './components/molecules/Navbar/Navbar';
import Frontpage from './components/pages/Frontpage/Frontpage';
import About from './components/pages/About/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Portfolio from './components/pages/Portfolio/Portfolio';
import CV from './components/pages/CV/CV';
import NotFound from './components/pages/NotFound/NotFound';
import projects from './projects.json';
import ProjectDetails from './components/molecules/ProjectDetails/ProjectDetails';
import { PortfolioProject } from './types/portfolio';

const App = (): JSX.Element => (
  <div className='App'>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Frontpage />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/cv' element={<CV />} />
        <Route path='*' element={<NotFound />} />
        {(projects as PortfolioProject[]).map((project) => (
          <Route
            key={project.title}
            path={`portfolio/${project.url}`}
            element={<ProjectDetails project={project} />}
          />
        ))}
      </Routes>
    </Router>
  </div>
);

export default App;
