import { useState, useEffect } from "react";
import { Orientation } from "../types/orientation";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default (): Orientation => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!windowDimensions || !windowDimensions.height || !windowDimensions.width)
    return "NONE";
  if (windowDimensions.height > windowDimensions.width) return "PORTRAIT";
  return "LANDSCAPE";
};
