import React, { useState } from 'react';
import './HoverButton.scss';

interface Props {
  defaultText: string | JSX.Element;
  hoverText: string | JSX.Element;
  fontSize?: string;
}

const HoverButton = ({
  defaultText,
  hoverText,
  fontSize,
}: Props): JSX.Element => {
  const _fontSize = fontSize || '1rem';
  const [hover, setHover] = useState(false);
  return (
    <div
      className='wrapper'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ fontSize: _fontSize }}
    >
      {hover ? hoverText : defaultText}
    </div>
  );
};

export default HoverButton;
