import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PortfolioProject } from '../../../types/portfolio';
import technologyToIcon from '../../../utils/technologyToIcon';
import './PortfolioElement.scss';

type Props = {
  project: PortfolioProject;
  index: number;
};

const indexToOriginClassName = (index: number): string => {
  if (index < 4) return 'top-origin';
  if (index < 8) return 'mid-origin';
  return 'bot-origin';
};

const PortfolioElement = ({ project, index }: Props) => {
  const [isHoveringElement, setIsHoveringElement] = useState(false);
  const technologyIcons = project.technologies.map(technologyToIcon);

  return (
    <Link to={project.url}>
      <div
        className={`portfolio-element-wrapper ${indexToOriginClassName(index)}`}
        style={{ backgroundImage: `url("${project.coverImage}")` }}
        onMouseOver={() => setIsHoveringElement(true)}
        onMouseOut={() => setIsHoveringElement(false)}
      >
        {isHoveringElement && (
          <>
            <div className='technologies-icon-wrapper'>
              {technologyIcons.map((tech) => (
                <img key={tech} className='technologies-icon' src={tech} />
              ))}
            </div>
            <div className='name-text-wrapper'>
              <p className='name-text'>{project.title}</p>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default PortfolioElement;
