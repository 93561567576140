import React, { useEffect, useRef, useState } from 'react';
import './About.scss';
import SpaceSimulation from '../../../threejs/SpaceSimulation';
import { FcRotateToLandscape } from 'react-icons/fc';
import useOrientation from '../../../hooks/useOrientation';
import { useNavigate } from 'react-router-dom';

const About = (): JSX.Element => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [spaceSim, setSpaceSim] = useState<SpaceSimulation>();
  const orientation = useOrientation();
  const navigate = useNavigate();

  const onFinishSpaceSim = () => {
    navigate('/portfolio');
  };

  useEffect(() => {
    if (!canvas || !canvas.current) return;

    setSpaceSim(new SpaceSimulation(canvas.current, 'about', onFinishSpaceSim));
  }, [canvas]);

  useEffect(() => {
    if (!spaceSim) return;
    setSpaceSim(
      new SpaceSimulation(canvas.current!, 'about', onFinishSpaceSim)
    );
  }, [orientation]);

  return (
    <>
      <canvas ref={canvas} />
      <div
        className={`overlay ${
          window.innerHeight <= window.innerWidth && 'hide'
        }`}
      >
        <p>This page only works in landscape mode</p>
        <FcRotateToLandscape fontSize='6rem' className='flipIcon' />
      </div>
    </>
  );
};

export default About;
