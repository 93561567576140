import React from "react";
import "./Logo.scss";
import logo from "../../../LogoAnimated.svg";

const Logo = (): JSX.Element => {
  return (
    <div className="logo-wrapper">
      <img src={logo} />
    </div>
  );
};

export default Logo;
