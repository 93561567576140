import React, { useState } from 'react';
import './CV.scss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PdfNavButton from '../../atoms/PdfNavButton/PdfNavButton';
import PdfNavButtons from '../../molecules/PdfNavButtons/PdfNavButtons';

const CV = (): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const { height } = useWindowDimensions();
  const [showNavButtons, setShowNavButtons] = useState(false);

  return (
    <div className='cv-wrapper'>
      <div
        onMouseEnter={() => setShowNavButtons(true)}
        onMouseLeave={() => setShowNavButtons(false)}
      >
        <Document file='CV.pdf'>
          <Page height={height} pageNumber={currentPage} />
        </Document>
        <PdfNavButtons
          height={height}
          show={showNavButtons}
          showLeft={currentPage === 2}
          showRight={currentPage === 1}
          onClick={(increment) =>
            increment ? setCurrentPage(2) : setCurrentPage(1)
          }
        />
      </div>
    </div>
  );
};

export default CV;
